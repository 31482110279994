import { render, staticRenderFns } from "./badge-list.html?vue&type=template&id=e0ebfc3e&scoped=true&external"
import script from "./badge-list.js?vue&type=script&lang=js&external"
export * from "./badge-list.js?vue&type=script&lang=js&external"
import style0 from "./badge-list.scss?vue&type=style&index=0&id=e0ebfc3e&prod&scoped=true&lang=scss&external"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "e0ebfc3e",
  null
  
)

export default component.exports