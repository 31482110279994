import osintAxios from "@shared/light-ray/axios/osint-axios";
import {getBackgroundStatus} from "@shared/utils/monitoringTools.js";
import Badge from "@shared/saas-components/badge";
import neoSwitch from "@shared/components/toggle-switch";
import {mapGetters, mapActions} from "vuex";
import axios from "@/axios";
import NeoBadgeList from "@shared/saas-components/badge-list";
import {DateTime} from "luxon";
import ImagePlaceholder from "@shared/saas-components/image-placeholder";
import CanvasMenu from "@shared/saas-components/canvas-menu";
import {FEATURES} from "@shared/utils/constants";
import MinusIcon from "@shared/assets/minus-icon.svg";
import Check from "@shared/light-ray/assets/svg/check.svg";
import Question from "@shared/light-ray/assets/svg/potentialQuestionMark.svg";
import CrossRed from "@shared/light-ray/assets/svg/cross-red.svg";
import { checkPermission } from "@shared/utils/functions";

export default {
    name: "search-engine-card",
    components: {
        "neo-badge": Badge,
        neoSwitch,
        NeoBadgeList,
        ImagePlaceholder,
        CanvasMenu,
        confirmed: Check,
        unknown: MinusIcon,
        None: MinusIcon,
        potential: Question,
        irrelevant: CrossRed,
    },
    props: ["data",
        "monitoring",
        "onClick",
        "clickedLinks",
        "onHandleMarkAsDone",
        "isDoneLoading",
        "queryId",
        "searchedData",
        "analyzerCount",
        "currentTab",
        "autorun",
        "autoAnalyser",
        "mainEntity",
        "riskCategoriesOptions",
        "currentTreeSection",
        "disabled",
    ],
    data() {
        return {
            showList: false,
            analysisStart: false,
            dataValue: this.searchedData,
            openDetails: false,
            allCardDetails: {},
            case_id:'',
        };
    },
    methods: {
        ...mapActions({ fetchRiskScore: "CaseCreationStore/fetchRiskScore"}),
        checkPermission,
        getBackgroundStatus,
        closeCanvas(detail) {
            this.openDetails = false
            this.$emit("handleStatusUpdate", detail)
        },
        updateCardStatus(detail) {
            this.$emit("handleStatusUpdate", detail)
        },
        toogleDropdown() {
            if (!checkPermission("checkOutput.edit_card")) return;
            this.showList = !this.showList;
        },
        closeDropdown() {
            this.showList = false;
        },
        async selectDropdown(event, detail) {
            this.showList = false;
            let key = event.target.innerText.toUpperCase();
            if(key === "FALSE MATCHES") { 
                key = "IRRELEVANT";
            } else if(key === "UNRESOLVED") { 
                key = "UNKNOWN"
            }
            if (detail.card_status === key) {
                return;
            }

            if(key === "UNKNOWN"){
                key = 'None';
            }
                let url;
                let payload = {
                    _id: detail._id,
                    case_id: this.getNeoCaseId,
                    entity: detail.title,
                    query_id: this.$route.query.query_id,
                    tool_name: this.$route.query.check_name,
                    status: key,
                    main_entity_id: this.$route.query.entityId,
                    source_name: "search-engine",
                }
                if (detail.card_type === 'media') url = `/service/active-passive-media`;
                else url = `/search-engine/${detail._id}`;
                await axios.put(url, payload);
                this.$toast.success(this.$t('components.tools.search_engine.successfully_updated'));
                detail.status = key
                this.case_id = this.$route.query.caseid
                await this.fetchRiskScore({ case_id: this.case_id })
                this.updateCardStatus(detail)
            
        },

        getComponent(status) {
            return status || "unknown";
        },

        getCardStatusText(status) {
            return (status === 'IRRELEVANT' ? 'FALSE MATCHES' : 
                (status === 'UNKNOWN' || status === "None" || !status) ? 'UNRESOLVED' : status
            )
        },

        getStatusClass(status) {
            switch(status) {
                case 'confirmed': {
                    return "text-lime-600 border-lime-600";
                }
                case 'potential': {
                    return "text-custom-orange border-custom-orange";
                }
                case 'irrelevant': {
                    return "text-custom-red border-custom-red";
                }
                case 'unknown': {
                    return "text-gray-400 border-gray-400";
                }
                case 'none': {
                    return "text-gray-400 border-gray-400";
                }
                default : {
                    return  "text-primary border-primary-focus"
                }
            }
        },

        getBorderColorClass(status) {

            switch(status) {
                case 'confirmed': {
                    return "border-lime-600";
                }
                case 'potential': {
                    return "border-custom-orange";
                }
                case 'irrelevant': {
                    return "border-custom-red";
                }
                case 'unknown': {
                    return "border-gray-400";
                }
                case 'none': {
                    return "border-gray-400";
                }
                default : {
                    return  "border-gray-100"
                }
            }
        },


        getHighlightShadow(status) {
            switch(status) {
                case 'confirmed': {
                    return "box-shadow: 0 4px 6px rgba(132, 204, 22, 0.6)"
                }
                case 'potential': {
                    return "box-shadow: 0 4px 6px rgba(252, 103, 19, 0.6)"
                }
                case 'irrelevant': {
                    return "box-shadow: 0 4px 6px rgba(235, 49, 49, 0.6)"
                }
                default : {
                    return  "box-shadow: 0 4px 6px rgba(209, 213, 219, 0.6)"
                }
            }
        },
        
        showCardDetails(detail) {
            this.openDetails = true
            let cardDetails = {
                case_id: this.getNeoCaseId,
                entity: detail.title,
                query_id: this.$route.query.query_id,
                tool_name: this.$route.query.check_name,
                status: detail.status,
                main_entity_id: this.$route.query.entityId,
                source_name: "search-engine",
                relations: detail.relations ?? [],
            }
            this.allCardDetails = {...this.data, ...cardDetails};
        },

        permissionExists(key) {
            return this.getPermissions.find((res) => FEATURES.tools[res] == key);
        },
        async startAnalyzer(cardData) {
            let config = {};
            this.getAnalyzerOptions.forEach((res) => {
                if (this.permissionExists(res.key)) {
                    config[res.payload_name] = res.selected ? res.selected : res.analysis;
                }
            });
            this.analysisStart = true;
            const url = `/case-analysis/${cardData.case_id}/analysis`;
            try {
                await axios.post(url, {
                    record_count: 1,
                    tool: "search-engine-tool",
                    queries: [
                        {
                            query_id: cardData.query_id ? cardData.query_id : this.queryId,
                            doc_ids: [cardData._id],
                        },
                    ],
                    config: config,
                });
                this.startAnalyzerInInterval(cardData._id);
            } catch (err) {
                this.$toast.error(this.$t("general.default_error"));
                this.analysisStart = false;
            }
        },
        startAnalyzerInInterval(cardId) {
            this.$emit("handleAnalysisStatus", {id: cardId});
        },
        async showPreview() {
            this.previewItem = this.data;
            const response = await osintAxios.post("/api/v1/search-engine/preview", {
                item: this.isMonitoringTab ? this.data.mongo_doc_id : this.data._id,
                case_id: this.data.case_id,
            });

            if (response.data.status) {
                this.showPreviewModal = true;
                let yourWindow = window.open();
                yourWindow.opener = null;
                yourWindow.location = response.data.preview;
                yourWindow.target = "_blank";
            } else {
                this.$toast.error(response.data.message);
            }
        },
        getResultFormattedTime(ts) {
            let date = DateTime.fromISO(ts);
            return date.toFormat("dd MMM yyyy");
        },
        handleOnClick(link, data) {
            // window.open(link, "_blank", "noopener,noreferrer");
            open(link, "_blank", "noopener,noreferrer");
            const id = this.isMonitoringTab ? data.mongo_doc_id: data._id;
            this.onClick(link, id);
        },
        visitedClass(data, clickedLinks) {
            return data.visited || clickedLinks.find((ele) => ele.links.includes(data.link)) || (this.getReadOnlyMode && "cursor-not-allowed");
        },
        checkedInput(data, clickedLinks) {
            return data.done || clickedLinks.find((ele) => ele.doneLinks.includes(data.link)) || data.changes_viewed;
        },
        disabledCheck(data, clickedLinks) {
            return data.visited || this.getReadOnlyMode || clickedLinks.find((ele) => ele.links.includes(data.link)) || data.isDoneLoading || this.isDoneLoading;
        },
        async handleMonitoring() {
            // event.stopPropagation();
            this.data.monitoring_status = !this.data.monitoring_status;
            this.$forceUpdate();
            await axios.post("/data-monitoring", {
                product_name: this.getProduct.name,
                product_id: this.getProduct.key,
                case_id: this.$route.params.id,
                query: this.data._id,
                tool: this.$route.params.toolName,
                frequency: 7,
                monitoring_type: "card",
                inherit_product_settings: false,
                inherit_product_id: "",
                card_id: this.data._id,
                card_source: this.$route.params.toolName,
                status: this.data.monitoring_status ? "enabled" : "disabled",
            });
        },
        handleMarkAsDone(event, data) {
            // if (this.isMonitoringTab) {
            //     this.onHandleMarkAsDone(event, data.mongo_doc_id, data.link, data);
            // } else {
                this.onHandleMarkAsDone(event, data._id, data.link, data);
            // }
        },
        fetchList(labels) {
            if (labels && labels.length > 0) {
                return labels.map((label) => ({label: label.category}));
            } else return [];
        },
        getSentimentStatusClass(status) {
            let classes = "";
            if (status === "Neutral") classes += "bg-gray-500";
            else if (status === "Positive") classes += "bg-green-500";
            else if (status === "Negative") classes += "bg-red-500";
            return classes;
        },

        checkCategories(item) {
            let risk = this.riskCategoriesOptions.find(v => v.category == item);
            if (risk) {
                return risk.category_type === 'Risk Categories' ? 
                    ((this.data.entity_status === 'Amber') ? 'amberLabel' : 
                        'redLabel') : 
                    'greenLabel';
            }
        },
    },
    computed: {
        ...mapGetters(["getProduct", "getAnalyzerOptions", "getPermissions", "getAutoAnalyze", "getNeoCaseId"]),
        getReadOnlyMode() {
            return this.$store.getters.getReadOnlyMode;
        },
        isMonitoringTab() {
            return this.monitoring;
        },
        showAnalyser() {
            return this.getAnalyzerOptions.filter((res) => res.selected);
        },
        CompletedData() {
            let isNull = this.dataValue?.some((el) => el.analysis_status === "pending" || !el.analysis_status);
            return isNull;
        },
        autoAnalyser() {
            return this.getAutoAnalyze;
        },
    },
};
