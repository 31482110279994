<!-- Localized -->
<template>
    <div>
        <modal name="search-engine-cheat-sheet" height="600px" width="800px">
            <div class="flex mt-4 justify-end mr-4 hover:text-brand-hover cursor-pointer" @click="hideModal('search-engine-cheat-sheet')">
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-x">
                    <line x1="18" y1="6" x2="6" y2="18" />
                    <line x1="6" y1="6" x2="18" y2="18" />
                </svg>
            </div>

            <div class="p-5 pt-2 space-y-2 h-full overflow-y-auto scroll-bar">
                <div class="pb-3 font-bold">{{ $t('components.tools.search_engine.se_cheatsheet.the_search_engine_tool_uses_boolean_logic') }}</div>
                <div class="text-yellow-600">
                    <span class="font-bold">{{ $t('components.tools.search_engine.se_cheatsheet.quotation_marks') }}</span> <span class="underline mr-2">{{ $t('components.tools.search_engine.se_cheatsheet._') }}</span> <span class="text-yellow-600"><span class="italic">{{ $t('components.tools.search_engine.se_cheatsheet.used_for') }}</span>{{ $t('components.tools.search_engine.se_cheatsheet.searching_an_exact_phrase') }}</span>
                </div>
                <div><span class="text-sm font-bold italic">{{ $t('components.tools.search_engine.se_cheatsheet.eg') }}</span><span class="text-purple-600">{{ $t('components.tools.search_engine.se_cheatsheet.john_oliver_doe') }}</span></div>
                <div class="text-yellow-600"><span class="font-bold">{{ $t('components.tools.search_engine.se_cheatsheet.or') }}</span><span class="italic">{{ $t('components.tools.search_engine.se_cheatsheet.used_for') }}</span>{{ $t('components.tools.search_engine.se_cheatsheet.searching_for_multiple_terms') }}</div>
                <div><span class="text-sm font-bold italic">{{ $t('components.tools.search_engine.se_cheatsheet.eg') }}</span><span class="text-purple-600">{{ $t('components.tools.search_engine.se_cheatsheet.john_doe_or_jonathon_smith') }}</span></div>
                <div><span class="text-sm font-bold italic">{{ $t('components.tools.search_engine.se_cheatsheet.eg') }}</span> <span class="text-purple-600">{{ $t('components.tools.search_engine.se_cheatsheet.john_or_jonathon_doe_london_or_manchester_or_rome') }}</span></div>
                <div class="leading-normal">
                    <span class="font-serif">{{ $t('components.tools.search_engine.se_cheatsheet.the_word_or_will_pick_up_the_word_or_quotation_either_side_of_it_phrases_with_multiple_words_must_be_in_quotation_marks_eg') }}</span>
                    <span class="text-purple-600 font-serif">{{ $t('components.tools.search_engine.se_cheatsheet.united_states_of') }}<span class="font-bold">{{ $t('components.tools.search_engine.se_cheatsheet.america') }}</span>{{ $t('components.tools.search_engine.se_cheatsheet.or') }}<span class="font-bold">{{ $t('components.tools.search_engine.se_cheatsheet.new') }}</span>{{ $t('components.tools.search_engine.se_cheatsheet.zealand') }}</span>{{ $t('components.tools.search_engine.se_cheatsheet.will_choose_between_the_emboldened_words_and_search_for') }}<span class="text-purple-600">{{ $t('components.tools.search_engine.se_cheatsheet.united_states_of_america_zealand') }}</span>{{ $t('components.tools.search_engine.se_cheatsheet.and') }}<span class="text-purple-600">{{ $t('components.tools.search_engine.se_cheatsheet.united_states_of_new_zealand') }}</span>{{ $t('components.tools.search_engine.se_cheatsheet.therefore_the_search_should_be') }}<span class="text-purple-600 font-sans">{{ $t('components.tools.search_engine.se_cheatsheet.united_states_of_america_or_new_zealand') }}</span>
                </div>
                <div class="text-yellow-600"><span class="font-bold">{{ $t('components.tools.search_engine.se_cheatsheet.hyphen_') }}</span>{{ $t('components.tools.search_engine.se_cheatsheet.used_for_removing_a_word_from_the_search_results') }}</div>
                <div>
                    <span class="text-sm font-bold italic">{{ $t('components.tools.search_engine.se_cheatsheet.eg') }}</span> <span class="text-purple-600 font-sans">{{ $t('components.tools.search_engine.se_cheatsheet.bill_gates_') }}<span class="text-sm">{{ $t('components.tools.search_engine.se_cheatsheet.microsoft') }}</span></span>
                </div>
                <div>
                    <span class="text-sm font-bold italic">{{ $t('components.tools.search_engine.se_cheatsheet.eg') }}</span> <span class="text-purple-600 font-sans">{{ $t('components.tools.search_engine.se_cheatsheet.bill_gates_') }}<span class="text-sm">{{ $t('components.tools.search_engine.se_cheatsheet.melinda_gates') }}</span></span>
                </div>
                <div>{{ $t('components.tools.search_engine.se_cheatsheet.the_hyphen_must_be_connected_to_the_word_or_quotation_with_no_space_between_them') }}</div>
                <div class="text-yellow-600">
                    <span class="font-bold">{{ $t('components.tools.search_engine.se_cheatsheet.wildcard') }}<span style="position: relative; top: -0.3em">{{ $t('general.asterisk') }}</span></span>{{ $t('components.tools.search_engine.se_cheatsheet.used_for_searching_phrases_with_unknown_words') }}</div>
                <div>
                    <span class="text-sm font-bold italic">{{ $t('components.tools.search_engine.se_cheatsheet.eg') }}</span><span class="text-purple-600 font-sans">{{ $t('components.tools.search_engine.se_cheatsheet.john') }}<span style="position: relative; top: -0.3em">{{ $t('general.asterisk') }}</span>{{ $t('components.tools.search_engine.se_cheatsheet.doe') }}</span>
                </div>
                <div class="text-yellow-600"><span class="font-bold">{{ $t('components.tools.search_engine.se_cheatsheet.search_lists') }}</span>{{ $t('components.tools.search_engine.se_cheatsheet.used_for_adding_lists_of_wordsphrases_to_your_search') }}</div>
                <div><span class="text-sm font-bold italic">{{ $t('components.tools.search_engine.se_cheatsheet.eg') }}</span><span class="text-purple-600 font-sans">{{ $t('components.tools.search_engine.se_cheatsheet.john_or_jonathan_doe_english_criminal') }}</span></div>
                <div class="leading-normal font-serif">{{ $t('components.tools.search_engine.se_cheatsheet.these_lists_must_include_the_language_and_the_list_name_from_the_platform_library_in_curly_brackets_with_capital_letters_they_only_work_with_languages_available_in_the_prebuilt_queries_language_dropdown') }}</div>
                <div class="leading-normal mb-5">
                    <ul>{{ $t('components.tools.search_engine.se_cheatsheet.examples_include') }}<li class="ml-10">{{ $t('components.tools.search_engine.se_cheatsheet.english_criminal') }}</li>
                        <li class="ml-10">{{ $t('components.tools.search_engine.se_cheatsheet.italian_scandal') }}</li>
                        <li class="ml-10">{{ $t('components.tools.search_engine.se_cheatsheet.russian_harassment') }}</li>
                        <li class="ml-10">{{ $t('components.tools.search_engine.se_cheatsheet.chinesesimplified_sexual') }}</li>
                        <li class="ml-10">{{ $t('components.tools.search_engine.se_cheatsheet.english_climate_change_') }}<span class="italic">{{ $t('components.tools.search_engine.se_cheatsheet.not_available_to_all_users') }}</span></li>
                        <li class="ml-10 appearance-none"></li>
                    </ul>
                </div>
            </div>
        </modal>
    </div>
</template>
<script>
export default {
    methods: {
        hideModal(modalname) {
            this.$modal.hide(modalname);
        },
    },
};
</script>

<style>
/* .vm--modal{
        width: 800px!important;
        top:100px !important
} */
</style>
