import axios from "@/axios";
const NeoInput = () => import("@/components/input");
const NeoLoader = () => import("@/components/loader");

export default {
    name: "analyse-modal",
    components: {
        NeoInput,
        NeoLoader,
    },
    props: {
        searchqueries: {
            type: Array,
        },
        totalqueries: {
            type: Array,
        },
    },
    data() {
        return {
            inputValue: "",
            isLoading: false,
        };
    },
    computed: {},
    mounted() {},
    methods: {
        async onAnalyse() {
            if (this.inputValue > 0) {
                this.isLoading = true;
                let queries = this.searchqueries.map((srchQuery) => ({query_str: srchQuery.query, checked: true, masked_query: srchQuery.maskedQuery, category: srchQuery.category, label: srchQuery.label}));
                this.totalqueries.forEach((element) => {
                    let exist = queries.some((quer) => quer.query_str === element.search_query && quer.category === element.category && quer.label === element.label);
                    if (!exist) {
                        queries.push({checked: element.checked, masked_query: element.maskedQuery, category: element.category, query_str: element.search_query, label: element.label});
                    }
                });
                try {
                    await axios.post("/search-engine/analysis-queries", {case_id: this.$route.params.id, queries, data_size: this.inputValue});
                } catch (error) {
                    console.log(error);
                }
                this.isLoading = false;
                this.$modal.hide("analyse-modal");
                this.$emit("onAnalyse");
            } else {
                this.$toast.info(this.$t('components.tools.search_engine.articles_should_be_a_positive_number'));
            }
        },
        beforeOpen() {
            this.inputValue = "";
            this.isLoading = false;
        },
        closeModal() {
            this.$modal.hide("analyse-modal");
        },
    },
};
